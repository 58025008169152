import { useState, useEffect, useContext, useMemo } from 'react';
import { debounce } from 'lodash';
import { PipelineView } from '../types/PipelineView';
import { ButtonSwitch } from '../../controls/';
import { TransactionsSearchInput } from '../../filters/TransactionsSearchInput';
import IconSVG from '../../../styles/svg-icons';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { createAmrPipelineActions, imUserConfigActions } from '../../../actions/';
import { UserConfigFilter } from '../../../types/user-config/UserConfigFilter';
import { AlertOption, StatisticsAlertOption } from '../../../types/amr-pipeline/enums/AlertOption';
import PipelineContext from './PipelineContext';
import { amrDealsSelector, arrangerPipelineDealsSelector } from '../../../selectors/amr-pipeline.selector';
import { IssuanceMonitorTab } from '../types/PipelineTabTypes';
import { amrArrangerPipelineActions } from '../../../actions/amr-arranger-pipeline.actions';
import { roles } from '../../../constants';
import { user } from '../../../user';
import { ArrangerPipelineSearchDeals } from './arranger-pipeline/ArrangerPipelineSearchDeal';
import ExportButton from '../common/ExportButton';
import { AlertsButton } from '../../common/AlertsButton';
import { ActionBlocker } from '../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { UserConfigType } from '../../../types/user-config/UserConfigType';
import { ImEmailAlertsPopup } from './email-alerts/issuance-monitor/ImEmailAlertsPopup';
import { ApEmailAlertsPopup } from "./email-alerts/arranger-pipeline/ApEmailAlertsPopup";

interface Props {
    viewMode: PipelineView;
    tab: IssuanceMonitorTab;
    onViewModeChange: (viewMode: PipelineView) => void;
}

export const IssuanceMonitorTopPanel = ({ tab, viewMode, onViewModeChange }: Props) => {
    const { pipelineType, setTab, withBlockedSearchAndFilter } = useContext(PipelineContext);
    const amrPipelineActions = createAmrPipelineActions(pipelineType);

    const dispatch = useAppDispatch();

    const {
        isUpdating,
        imAlertConfig,
        newTransactionAlertConfig,
        weeklyStatsAlertConfig,
        filtersConfig,
        apAlertConfig,
    } = useSelector((state: AppState) => state.imUserConfig);
    const { classes, isExportLoad: isDealsPipelineExportLoad } = useSelector(
        amrDealsSelector(pipelineType),
        (oldState, newState) =>
            oldState.classes === newState.classes && oldState.isExportLoad === newState.isExportLoad,
    );
    const { arrangerPipelines, anyPipelineExists, isExportLoad: isArrangerPipelineExportLoad, dealsSearchItems, selectedDealsLegalNames } = useSelector(arrangerPipelineDealsSelector);

    const isBD = user.hasRoles(...roles.bd());
    const isEditArrangerPipelineAvailable = isBD || user.hasRoles(...roles.admin(), roles.DataEntry);

    const isArrangerPipelineTab = tab === IssuanceMonitorTab.ArrangerPipeline;

    const imAlertOption = imAlertConfig.value.alertOption;
    const newTransAlertOption = newTransactionAlertConfig.value.alertOption;
    const weeklyStatsAlertOption = weeklyStatsAlertConfig.value.emailOption;
    const filterAlertOptions = filtersConfig.ImFilter.value;
    const arrangerPipelineAlertOption = apAlertConfig.value.alertOption;

    const withSelectedAlerts = useMemo(() => {
        if (isArrangerPipelineTab) {
            return arrangerPipelineAlertOption !== AlertOption.Never;
        }

        const alertsOptions = [
            imAlertOption,
            newTransAlertOption,
            weeklyStatsAlertOption,
            ...filterAlertOptions.map(f => f.alertOption),
        ];

        return alertsOptions.some(a => a !== AlertOption.Never);
    }, [
        isArrangerPipelineTab,
        imAlertOption,
        newTransAlertOption,
        weeklyStatsAlertOption,
        filterAlertOptions,
        arrangerPipelineAlertOption,
    ])


    const isExportDisabled =
        isArrangerPipelineTab
            ? !arrangerPipelines?.length
            : !classes?.length || isDealsPipelineExportLoad;

    const isExportLoad =
        isArrangerPipelineTab ? isArrangerPipelineExportLoad : isDealsPipelineExportLoad;

    const [showAlertsPopup, setAlertsPopup] = useState(false);

    useEffect(() => {
        if (!isUpdating) {
            setAlertsPopup(false);
        }
    }, [isUpdating]);


    const searchDeals = () => {
        dispatch(amrPipelineActions.apply());
    };

    const searchArrangerPipeline = (dealsLegalNames: string[]) => {
        dispatch(amrArrangerPipelineActions.selectDealsFromSearch(dealsLegalNames));
        dispatch(amrArrangerPipelineActions.applyFilterAndSearch());
    }

    const handleSearchDeals = debounce(searchDeals, 50);
    const handleSearchArrangerPipeline = debounce(searchArrangerPipeline, 50);

    const handleExport = () => {
        if (isArrangerPipelineTab) {
            dispatch(amrArrangerPipelineActions.exportPipelines());
            return;
        }

        dispatch(amrPipelineActions.exportTransactionsClasses(viewMode));
    }

    const onShowPopup = () => setAlertsPopup(true);
    const onHidePopup = () => setAlertsPopup(false);

    const onSaveImEmailAlerts = (everySingleUpdate: AlertOption, newTransactionUpdate: AlertOption, weeklyStatistic: StatisticsAlertOption, filterStates: UserConfigFilter[]) => {
        const updatedAlerts = filterStates.map(f => ({
            referenceName: f.referenceName,
            alertOption: f.alertOption,
        }));

        dispatch(imUserConfigActions.updateUserFilterEmailAlerts(everySingleUpdate, newTransactionUpdate, weeklyStatistic, updatedAlerts));
    };

    const onSaveApEmailAlerts = (upcomingOption: AlertOption) => {
        const apConfigs = [
            {
                type: UserConfigType.arrangerPipelineAlert,
                value: {
                    alertOption: upcomingOption
                },
            },
        ];
        dispatch(imUserConfigActions.updateUserConfig(apConfigs));
    };

    const renderEmailAlertsPopup = () => {
        if (!showAlertsPopup) {
            return null;
        }

        return isArrangerPipelineTab ? (
            <ApEmailAlertsPopup
                onSave={onSaveApEmailAlerts}
                onClose={onHidePopup}
            />
        ) : (
            <ImEmailAlertsPopup
                onSave={onSaveImEmailAlerts}
                onClose={onHidePopup}
            />
        );
    };

    return (
        <>
            {!isArrangerPipelineTab && (
                <ButtonSwitch
                    className="control-group-slide"
                    sourceItems={[
                        { title: 'Deal View', key: PipelineView.Deal },
                        { title: 'Class View', key: PipelineView.Class },
                    ]}
                    selectedValue={viewMode}
                    onSelectionChange={onViewModeChange}
                    disabled={withBlockedSearchAndFilter}
                />
            )}
            <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                {blocked =>
                    isArrangerPipelineTab ? (
                        <ArrangerPipelineSearchDeals
                            selectedSearchItems={selectedDealsLegalNames}
                            placeholder="Search by deal name"
                            onSearch={handleSearchArrangerPipeline}
                            data={dealsSearchItems}
                            disabled={withBlockedSearchAndFilter || blocked}
                        />
                    ) : (
                        <TransactionsSearchInput
                            disabled={blocked || withBlockedSearchAndFilter}
                            className="control-search-groups"
                            onSearch={handleSearchDeals}
                        />
                    )
                }
            </ActionBlocker>
            <div className="flex-item-right controls filter-actions-box">
                {isArrangerPipelineTab && isEditArrangerPipelineAvailable && (
                    <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess} overrideRequiredFeatures={isBD}>
                        {blocked => (
                            <button
                                disabled={!anyPipelineExists || blocked}
                                onClick={() => setTab && setTab(IssuanceMonitorTab.ArrangerPipelineEdit)}
                                className="btn-link"
                            >
                                <IconSVG name="edit-pencil" width="16" height="16" />
                                Edit List
                            </button>
                        )}
                    </ActionBlocker>
                )}
                <ActionBlocker
                    feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                    noTooltip
                >
                    {(blocked) => (
                        <AlertsButton
                            label="Alerts"
                            onClick={onShowPopup}
                            isSelected={blocked ? false : withSelectedAlerts}
                        />
                    )}
                </ActionBlocker>
                <ExportButton isExportLoad={isExportLoad} handleExport={handleExport} disabled={isExportDisabled} />
            </div>

            {renderEmailAlertsPopup()}
        </>
    );
};
