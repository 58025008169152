import { useEffect, useState, useMemo, useContext } from 'react';
import { isEqual } from 'lodash';
import {
    createAmrPipelineActions, createSearchTransactionActions, imUserConfigActions,
} from '../../../actions/';
import { useSelector } from "react-redux";
import { DealView } from "./deal-view/DealView";
import { ClassView } from "./class-view/ClassView";
import { AppState } from "../../../types/state/AppState";
import { PipelineView } from "../types/PipelineView";
import { PipelineFilter } from "./Filter";
import { SyndicatePopup } from "./SyndicatePopup";
import { EmptyPlaceholder, Preloader } from "../../common";
import IconSVG from "../../../styles/svg-icons";
import { UserConfigType } from "../../../types/user-config/UserConfigType";
import { FilterButton } from "../../filters";
import { CustomizeColumnsDialog } from "../common/customize-columns/CustomizeColumnsDialog";
import { OriginatingTransactionClass } from '../../../types/amr-pipeline/models/OriginatingTransactionClass';
import { AmrClass } from '../../../types/amr-pipeline/models/AmrClass';
import { OriginatingTransaction } from '../../../types/amr-pipeline/models/OriginatingTransaction';
import { AmrTransaction } from '../../../types/amr-pipeline/models/AmrTransaction';
import { OnHoverTooltip } from '../../common';
import { FiltersManagement } from './filters-management/FiltersManagement';
import { ColumnsConfig, FiltersConfig } from '../../../types/user-config/UserConfig';
import PipelineContext from './PipelineContext';
import { amrPipelineSelector } from '../../../selectors/amr-pipeline.selector';
import { pipelineFilters } from '../../../constants/amr-pipeline';
import Summary from './summary/Summary';
import { PipelineType } from '../../../types/amr-pipeline/enums/PipelineType';
import { ImSubscriptionActionBlocker } from '../subscription/ImSubscriptionActionBlocker';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { RequiredFeature } from '../../access/RequiredFeature';
import { usePortfolios } from '../../../effects/usePortfolios';
import { isRequestFailed, isRequestSuccess } from '../../../utils';
import { user } from '../../../user';
import { roles } from '../../../constants';
import { AlertOption } from '../../../types/email-preferences/EmailPreferences';
import { BlockedFeatureContent, SubscribeLink } from "../../../components/access/BlockedFeatureText";
import { alertOptionTooltipText } from '../../../constants/amr-pipeline/alertOptions';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface PipelineProps {
    viewMode: PipelineView;
    onRedirect: () => void;
}

export function Pipeline({ viewMode, onRedirect }: PipelineProps) {
    const { pipelineType } = useContext(PipelineContext);
    const amrPipelineActions = useMemo(() => createAmrPipelineActions(pipelineType), [pipelineType]);
    const searchTransactionsActions = useMemo(() => createSearchTransactionActions(pipelineType), [pipelineType]);

    const dispatch = useAppDispatch();

    const [showColumnsDialog, setShowColumnsDialog] = useState(false);
    const {
        deals: {
            transactions,
            classes,
            isTransactionsSearching,
            isClassesSearching,
            syndicateContacts,
            wasInitialAccessTracked,
            numberOfHiddenTransactions,
            numberOfHiddenClasses,
        },
        searchTransactions: { searchTermItems },
        filter: { initialFilter, lastAppliedFilter, filterChanged, filterModified, selectedFilterReferenceName },
    } = useSelector(amrPipelineSelector(pipelineType));

    const columnsConfig = useSelector<AppState, ColumnsConfig[]>(state => state.imUserConfig.columnsConfig);
    const filtersConfig = useSelector<AppState, FiltersConfig>(state => state.imUserConfig.filtersConfig);
    const isLoading = useSelector<AppState, boolean>(state => state.imUserConfig.isLoading);
    const isUpdating = useSelector<AppState, boolean>(state => state.imUserConfig.isUpdating);

    const isFilterApplied = useMemo(() => !isEqual(lastAppliedFilter, initialFilter), [lastAppliedFilter, initialFilter]);
    const isSearching = isTransactionsSearching || isClassesSearching;
    const isDataLoaded = !!(transactions && classes);
    const isPortfolioDisabled = user.hasRoles(roles.ArrangersClient);
    const isDealView = viewMode === PipelineView.Deal;
    const isDataAvailable = isDealView
        ? !!transactions?.length || !numberOfHiddenTransactions
        : !!classes?.length || !numberOfHiddenClasses;

    const { requestState } = usePortfolios(isPortfolioDisabled);
    const isPortfoliosLoaded = isPortfolioDisabled || isRequestSuccess(requestState) || isRequestFailed(requestState);

    useEffect(() => {
        dispatch(imUserConfigActions.getUserConfig(() => {
            dispatch(amrPipelineActions.init());
        }));

        return () => {
            dispatch(amrPipelineActions.reset());
            dispatch(searchTransactionsActions.reset());
        };
    }, [dispatch, amrPipelineActions, searchTransactionsActions]);

    useEffect(() => {
        !isUpdating && setShowColumnsDialog(false);
    }, [isUpdating]);

    useEffect(() => {
        if (!wasInitialAccessTracked && isDataLoaded && pipelineType === PipelineType.Deals) {
            dispatch(amrPipelineActions.logInitialAccessToIM());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataLoaded])

    const renderView = () => {
        const data = isDealView ? transactions : classes;

        if ((searchTermItems.length || (isFilterApplied && filterChanged)) && !data?.length) {
            return (
                <EmptyPlaceholder />
            );
        }

        switch (viewMode) {
            case PipelineView.Deal:
                return (
                    data && <DealView
                        transactions={data as (OriginatingTransaction | AmrTransaction)[]}
                        columnsConfig={tabColumnsConfig}
                        onRedirect={onRedirect}
                    />
                );
            default:
                return (
                    data && <ClassView
                        classes={data as (OriginatingTransactionClass | AmrClass)[]}
                        columnsConfig={tabColumnsConfig}
                        onRedirect={onRedirect}
                    />
                );
        }
    };

    const tabColumnsConfig = useMemo(() => {
        const viewConfig = columnsConfig.find(({ type }) => {
            return type === (isDealView
                ? UserConfigType.imColumnsDealTab
                : UserConfigType.imColumnsClassTab
            )
        });

        if (!viewConfig) {
            return [];
        }

        return (viewConfig as ColumnsConfig).value
            .filter(column => !!column.displayed)
            .sort((a, b) => a.order - b.order);
    }, [columnsConfig, isDealView]);

    if (!isDataLoaded || !initialFilter || isLoading || !isPortfoliosLoaded) {
        return <Preloader inProgress={true} />;
    }

    const handleToggleFilterDefault = (referenceName: string, isDefault: boolean) => {
        dispatch(
            imUserConfigActions.setUserFilterParams(
                {
                    referenceName: referenceName,
                    default: isDefault,
                },
                UserConfigType.imFilter,
            ),
        );
    };

    const handleSaveFilterAlerts = (referenceName: string, alertOption: AlertOption, isDefault = false) => {
        dispatch(
            imUserConfigActions.setUserFilterParams(
                {
                    referenceName,
                    default: isDefault,
                    alertOption,
                },
                UserConfigType.imFilter,
            ),
        );
    };

    const handleDeleteFilter = (referenceName: string) =>
        dispatch(imUserConfigActions.deleteUserFilter(referenceName, UserConfigType.imFilter));

    const renderFiltersManagement = () => (

            <ImSubscriptionActionBlocker>
                {blocked => (
                    <FiltersManagement
                        filterType={pipelineType}
                        filters={filtersConfig[UserConfigType.imFilter].value}
                        onSaveFilterAlert={handleSaveFilterAlerts}
                        onMakeDefault={handleToggleFilterDefault}
                        onResetDefault={handleToggleFilterDefault}
                        onDeleteFilter={handleDeleteFilter}
                        selectedFilterReferenceName={selectedFilterReferenceName}
                        filterChanged={filterChanged}
                        filterModified={filterModified}
                        disabled={blocked}
                        onChange={() => dispatch(amrPipelineActions.apply())}
                        alertOptionInfo={{
                            [AlertOption.Instant]: alertOptionTooltipText[AlertOption.Instant],
                            [AlertOption.Daily]: alertOptionTooltipText[AlertOption.Daily],
                        }}
                    />
                )}
            </ImSubscriptionActionBlocker>
    );

    const renderColumnsCustomization = () => (
        <ImSubscriptionActionBlocker>
            {blocked => (
                <>
                    <OnHoverTooltip overlay={!blocked && "Customize Columns"}>
                        <FilterButton
                            className="btn-link"
                            onClick={() => setShowColumnsDialog(true)}
                            disabled={isSearching || blocked}
                        >
                            <IconSVG name="grid-swap" width={16} height={16} />
                            Columns
                        </FilterButton>
                    </OnHoverTooltip>
                    {showColumnsDialog && (
                        <CustomizeColumnsDialog defaultTab={viewMode} onClose={() => setShowColumnsDialog(false)} />
                    )}
                </>
            )}
        </ImSubscriptionActionBlocker>
    );

    const handleApplyFilter = () => dispatch(amrPipelineActions.apply());
    const handleResetFilter = () => dispatch(amrPipelineActions.filterReset());
    const handleFilterVisibilityChange = (filterName: string) => dispatch(amrPipelineActions.handleVisibilityFilter(filterName));

    return (
        <>
            <div className="sub-header sub-header-pipeline">
                <div className="sub-header-row type02 flex-row align-items-flex-start row-align-filters">
                    <PipelineFilter
                        onVisibilityChange={handleFilterVisibilityChange}
                        hiddenFilters={pipelineFilters.additionalFilters}
                        isSearching={isTransactionsSearching || isClassesSearching}
                        onApply={handleApplyFilter}
                        onReset={handleResetFilter}
                    />
                    <div className="flex-row flex-item-right save-manage-filters flex-none">
                        {renderFiltersManagement()}
                        <div className="vertical-divider" />
                        {renderColumnsCustomization()}
                    </div>
                </div>
            </div>
            <div className="container-flex pipeline-table position-relative">
                <Preloader inProgress={isSearching && (!transactions?.length || !classes?.length)}>
                    <RequiredFeature
                        feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                        renderBlockedContent={() => (
                            <BlockedFeatureContent
                                inline
                                className="im-stats-placeholder"
                                text={<><SubscribeLink /> to see Deal and Class stats.</>}
                            />
                        )}
                    >
                        <Summary />
                    </RequiredFeature>
                    <RequiredFeature
                        feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                        overrideRequiredFeatures={isDataAvailable}
                        renderBlockedContent={() => (
                            <BlockedFeatureContent
                                inline
                                className="empty-placeholder"
                                text={<><SubscribeLink /> to see the {isDealView ? 'deals' : 'classes'}.</>}
                            />
                        )}
                    >
                        {renderView()}
                    </RequiredFeature>
                </Preloader>
            </div>
            {syndicateContacts && (
                <SyndicatePopup
                    syndicateContacts={syndicateContacts}
                    onClose={() => dispatch(amrPipelineActions.resetSyndicateContacts())}
                />
            )}
        </>
    );
}
