import classnames from 'classnames';
import { useState, useMemo } from 'react';
import { alertOptionTooltipText, alertOptions as defaultAlertOptions } from '../../../../constants/amr-pipeline/alertOptions';
import { AlertOption } from '../../../../types/amr-pipeline/enums/AlertOption';
import { PortfolioUserConfigFilter, UserConfigFilter } from '../../../../types/user-config/UserConfigFilter';
import { Popup, PopupBody, PopupFooter, RadioButton } from '../../../controls';
import { Checkbox } from '../../../controls/Checkbox';
import { DropDownList } from '../../../controls/DropDownList';
import EmailInfoTooltip from '../../../common/EmailInfoTooltip';
import { FilterManagementForm, FilterSaveMode } from './types';

export type TRenderCustomAlertOptions =
    (state: FilterManagementForm, onChange: (nextState: FilterManagementForm) => void) => React.ReactNode;

interface FiltersManagementPopupProps {
    selectedReferenceName?: string,
    filters?: UserConfigFilter[];
    onSave: (data: FilterManagementForm) => void;
    onClose: () => void;
    isUpdating: boolean;
    showEmailNotificationsOptions?: boolean;
    alertOptions?: AlertOption[];
    defaultAlertOption?: AlertOption;
    alertOptionInfo?: { [key in AlertOption]?: string };
    renderCustomAlertOptions?: TRenderCustomAlertOptions;
}

export const FiltersManagementPopup = ({
    selectedReferenceName,
    onSave,
    onClose,
    isUpdating,
    filters,
    showEmailNotificationsOptions = true,
    alertOptions = defaultAlertOptions,
    defaultAlertOption = AlertOption.Daily,
    alertOptionInfo,
    renderCustomAlertOptions
}: FiltersManagementPopupProps) => {
    const getDefaultFilter = () => {
        if (!filters?.length) {
            return undefined;
        }

        if (selectedReferenceName) {
            return filters.find(x => x.referenceName === selectedReferenceName);
        }

        const [firstFilter] = filters;

        return firstFilter;
    };

    const defaultFilter = getDefaultFilter();

    const [form, setForm] = useState<FilterManagementForm>({
        name: defaultFilter?.name || '',
        referenceName: defaultFilter?.referenceName,
        mode: FilterSaveMode.Create,
        makeDefault: false,
        receiveEmailNotification: defaultAlertOption,
        bwicAlert: defaultAlertOption,
        inventoryAlert: defaultAlertOption
    });

    const selectedFilter = form.referenceName 
        ? filters?.find(f => f.referenceName === form.referenceName) 
        : defaultFilter;

    const [errors, setErrors] = useState<Record<string, string | null>>({});

    const dropdownListItems = useMemo(() => {
        if (!filters) {
            return [];
        }

        return filters.map((userConfigFilter: UserConfigFilter, index: number) => ({
            value: userConfigFilter.referenceName,
            text: userConfigFilter.name,
            selected: form.referenceName ? userConfigFilter.referenceName === form.referenceName : !index,
            payload: userConfigFilter,
        }));
    }, [filters, form.referenceName]);

    const validate = () => {
        const { mode, name } = form;
        let error: string | null = null;

        if (mode === FilterSaveMode.Update) {
            setErrors({});
            return true;
        }

        if (!name || !name.trim()) {
            error = 'Name is required';
        }

        if (filters?.some(x => x.name.toLocaleLowerCase() === name.toLocaleLowerCase())) {
            error = 'Please choose a unique name';
        }

        const newErrors = {
            ...errors,
            name: error,
        };

        setErrors(newErrors);

        return Object.values(newErrors).some(x => !x);
    };

    const handleSave = () => {
        const valid = validate();

        if (!valid) {
            return;
        }

        onSave(form);
    };

    const renderNameInput = (hasTitle?: boolean) => (
        <div className="form-control-wrapper">
            {hasTitle && <label className="text-sm save-as-label">Save as</label>}
            <input
                className={classnames('form-control', { 'is-invalid': !!errors.name })}
                type="text"
                placeholder="Filter Name"
                maxLength={256}
                onChange={(e) => {
                    const name = e.target.value;
                    setForm((prev) => ({ ...prev, name }));
                }}
                onBlur={validate}
                disabled={form.mode === FilterSaveMode.Update}
            />
            {errors.name && <div className="form-error">{errors.name}</div>}
        </div>
    );

    const renderSaveAsOnly = () => (
        <div className="form-row">
            {renderNameInput(true)}
        </div>
    );

    const renderWithUpdate = () => <>
        <div className="form-row">
            <RadioButton
                label="Save as"
                value={FilterSaveMode.Create}
                checked={form.mode === FilterSaveMode.Create}
                onChange={() => setForm((prev) => ({
                    ...prev,
                    mode: FilterSaveMode.Create,
                    makeDefault: false,
                    receiveEmailNotification: defaultAlertOption,
                    bwicAlert: defaultAlertOption,
                    inventoryAlert: defaultAlertOption
                }))}
            />
            {renderNameInput()}
        </div>
        <div className="form-row">
            <RadioButton
                label="Update existing"
                value={FilterSaveMode.Update}
                checked={form.mode === FilterSaveMode.Update}
                onChange={() => {
                    setForm((prev) => ({
                        ...prev,
                        mode: FilterSaveMode.Update,
                        ...(selectedFilter && {
                            makeDefault: selectedFilter.default || false,
                            receiveEmailNotification: selectedFilter.alertOption,
                            bwicAlert: (selectedFilter as PortfolioUserConfigFilter).bwicAlertOption,
                            inventoryAlert: (selectedFilter as PortfolioUserConfigFilter).dealerInventoryAlertOption
                        })
                    }))
                    setErrors({});
                }}
            />
            <DropDownList
                items={dropdownListItems}
                disabled={form.mode === FilterSaveMode.Create}
                onChange={(item) => setForm((prev) => ({
                    ...prev,
                    referenceName: item.value as string,
                    name: item.text,
                    makeDefault: item.payload.default || false,
                    receiveEmailNotification: item.payload.alertOption,
                    bwicAlert: (item.payload as PortfolioUserConfigFilter).bwicAlertOption,
                    inventoryAlert: (item.payload as PortfolioUserConfigFilter).dealerInventoryAlertOption
                }))}
            />
        </div>
    </>;

    const renderAlertOptions = () => {
        if (renderCustomAlertOptions) {
            return <div className="custom-alert-options">{renderCustomAlertOptions(form, setForm)}</div>;
        }

        return (
            <div className="controls-wrap-radio">
                {alertOptions.map((alertOption) => (
                    <div key={alertOption} className="controls-radio-item">
                        <RadioButton
                            name={`single-update-${alertOption}`}
                            label={alertOption}
                            checked={form.receiveEmailNotification === alertOption}
                            onChange={() => setForm((prev) => ({
                                ...prev,
                                receiveEmailNotification: alertOption
                            }))}
                        />
                        {alertOption !== AlertOption.Never && (
                            <EmailInfoTooltip
                                overlayText={alertOptionInfo?.[alertOption] ?? alertOptionTooltipText[alertOption]}
                            />
                        )}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <Popup
            renderInBody
            title="Save Filter"
            modalClass="modal-save-filters"
            onClose={onClose}
        >
            <PopupBody>
                {dropdownListItems.length
                    ? renderWithUpdate()
                    : renderSaveAsOnly()
                }
                {showEmailNotificationsOptions &&
                    <div className="row-checkboxes">
                        <h3>Email Alerts</h3>
                        {renderAlertOptions()}
                    </div>
                }
            </PopupBody>
            <PopupFooter>
                <Checkbox
                    label="Make filter default"
                    checked={form.makeDefault}
                    onChange={() => setForm((prev) => ({ ...prev, makeDefault: !prev.makeDefault }))}
                />
                <button className="btn btn-ghost" onClick={onClose}>Cancel</button>
                <button className="btn btn-main" disabled={isUpdating} onClick={handleSave}>Save</button>
            </PopupFooter>
        </Popup>
    );
};
