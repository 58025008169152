import { useContext } from 'react';
import { createAmrPipelineActions, createSearchTransactionActions } from '../../../actions';
import { roles } from '../../../constants';
import { PipelineType } from '../../../types/amr-pipeline/enums/PipelineType';
import { TransactionStatus } from '../../../types/amr-pipeline/enums/TransactionStatus';
import { TransactionType, TransactionTypes } from '../../../types/amr-pipeline/enums/TransactionType';
import { ShowFor } from '../../access';
import { EmptyPlaceholder } from '../../common'
import PipelineContext from './PipelineContext';
import { createFilterActions } from '../../../actions/filter.actions';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    onRedirect: () => void;
}

const createRedirectFilters = () => {
    const types = [TransactionType.newIssue, TransactionType.refi, TransactionType.reset];

    return {
        statuses: [{
            value: TransactionStatus.Active,
            text: TransactionStatus.Active,
        }],
        transactionTypes: types.map(type => ({
            value: type,
            text: TransactionTypes.find(x => x.value === type)?.text || ''
        })),
    };
};

const redirectFilter = createRedirectFilters();

export const PipelineEmptyPlaceholder = ({ onRedirect }: Props) => {
    const dispatch = useAppDispatch();
    const { pipelineType } = useContext(PipelineContext);

    // Link should change state in Deals tab only
    const amrPipelineActions = createAmrPipelineActions(PipelineType.Deals);
    const searchTransactionsActions = createSearchTransactionActions(PipelineType.Deals);
    const filterActions = createFilterActions(PipelineType.Deals);

    const handleViewTransactionsClick = () => {
        onRedirect();
        dispatch(amrPipelineActions.reset());
        dispatch(searchTransactionsActions.reset());

        dispatch(
            filterActions.setRedirectFilters(redirectFilter)
        );

        dispatch(amrPipelineActions.init());
    }

    if (pipelineType === PipelineType.Deals) {
        return (
            <EmptyPlaceholder text="There are no transactions yet."/>
        );
    }

    return (
        <EmptyPlaceholder text="">
            <ShowFor roles={roles.seller()}>
                <p>
                    No transactions with submitted IOIs yet.<br/>
                    Press the button below to view the transactions available for IOIs submission.
                </p>
                <button className="btn btn-main" onClick={handleViewTransactionsClick}>View Transactions</button>
            </ShowFor>
            <ShowFor roles={[...roles.bd(), ...roles.admin(), roles.DataEntry]}>
                <p>No transactions with submitted IOIs yet.</p>
            </ShowFor>
        </EmptyPlaceholder>
    )
}
